'use strict'; // Material design color palette: http://www.google.com/design/spec/style/color.html#color-color-palette
// _FIXED won't change in Light/Darkmode or Branding..

var green = '#69c350',

    /*!--green--*/
    white = '#fff',
    black87 = 'rgba(0, 0, 0, 0.87)',
    black54 = 'rgba(0, 0, 0, 0.54)',
    cellBgDark = '#212529',
    white70 = 'rgba(255, 255, 255, 0.70)';
var ColorLight = {
    GREEN: green,
    BRAND_COLOR: "#69c350",

    /*!--brand-color--*/
    RED: "#e73246",

    /*!--red--*/
    ORANGE: '#f7b55C',

    /*!--orange--*/
    PURPLE: '#b962d9',

    /*!--purple--*/
    BLUE: '#00b0db',

    /*!--blue--*/
    ANTHRACITE: '#575C61',

    /*!--anthracite--*/
    YELLOW_FIXED: '#fdd835',
    WHITE: white,
    OAK: '#BCA78A',
    ALEXA_BLUE: '#5FCAF4',
    // SPECIFIC
    OVERLAY_BG: 'rgba(22, 22, 22, 0.60)',
    STATUS_BAR_TRANSPARENT: 'rgba(0, 0, 0, 0.15)',
    TITLE_BAR_TRANSPARENT: 'rgba(0, 0, 0, 0.10)',
    BUTTON_GLOW: '#4E5761',
    // TODO - always used within an LxButton, make it default there
    GRID_LINES: 'rgba(0, 0, 0, 0.10)',
    // type A
    COMFORT_CTRL_BG: '#e0e0e0',
    APP_BACKGROUND: '#eeeeee',
    APP_BACKGROUND_SECONDARY: 'rgba(255, 255, 255, 1)',
    APP_BACKGROUND_TERTIARY: 'rgba(255, 255, 255, 1)',
    CELL_BACKGROUND: '#ffffff',
    CONTENT_INFO_BACKGROUND: "rgba(255,255,255,0.2)",
    COMFORT_UI_CELL_BACKGROUND: "rgba(222, 222, 222, 0.24)",
    CARD_BACKGROUND: 'rgba(255, 255, 255, 0.24',
    BORDER_A: 'rgba(0, 0, 0, 0.12)',
    ICON_A: black54,
    DISCLOSURE_ICON_A: black87,
    STATE_INACTIVE: 'rgba(0, 0, 0, 0.24)',
    STATE_INACTIVE_FIXED: 'rgba(0, 0, 0, 0.24)',
    // TODO the color STATE_INACTIVE changes to -b in dark mode -> must not be in ComfortMode
    STATE_ACTIVE: green,
    SEGMENT_SELECTION_BORDER: black54,
    DAYTIMER_SCALE: 'rgba(255, 255, 255, 0.24)',
    // type B
    APP_BACKGROUND_B: 'black',
    APP_BACKGROUND_SECONDARY_B: 'rgba(49, 55, 61, 1)',
    APP_BACKGROUND_TERTIARY_B: 'rgba(33,37,41,1)',
    CELL_BACKGROUND_B: cellBgDark,
    BORDER_B: 'rgba(255, 255, 255, 0.12)',
    ICON_B: 'rgba(255, 255, 255, 0.70)',
    DISCLOSURE_ICON_B: '#ffffff',
    STATE_INACTIVE_B: 'rgba(102,115,128,0.24)',
    STATE_ACTIVE_B: green,
    SEGMENT_SELECTION_BORDER_B: 'rgba(255, 255, 255, 0.70)',
    // TEXT
    // type a
    TEXT_A: black87,
    TEXT_SECONDARY_A: black54,
    TEXT_HINT_A: 'rgba(0,0,0,0.24)',
    TEXT_INACTIVE: black54,
    TEXT_ACTIVE: green,
    CARD_TEXT_A: '#333333',
    // type b
    TEXT_B: '#ffffff',
    TEXT_SECONDARY_B: "#93A5B8",
    TEXT_HINT_B: 'rgba(255,255,255,0.30)',
    TEXT_INACTIVE_B: "#93A5B8",
    TEXT_ACTIVE_B: green,
    CARD_TEXT_B: 'white',
    // OTHERS - isn't necessary to use these ones in JS and CSS
    // general
    STRIPED_BG_STRIPES: 'rgba(255, 255, 255, 0.1)',
    WEBINTERFACE_BG: '#646464',
    // active miniserver
    STATISTIC_BUBBLE_BG: 'rgba(255, 255, 255, 0.9)',
    TIME_LINE_VALUE_INDICATORS: black87,
    ColorUiElementBgB: '#4E5761',
    // controls
    AUDIO_ZONE_SLIDER_COLOR: 'white',
    MEDIA_DEFAULT_ICON_COLOR: "#e4e4e4",
    LOAD_MANAGER_SUBTITLE: "#73818F",
    CLEAR: "rgba(0,0,0,0)",
    Dimmer: {
        OFF: "#c2c2c2",
        // State inactive on white ground.
        ON: "#ffe57f" // default on = warm white

    }
};
var ColorDark = cloneObjectDeep(ColorLight);
ColorDark.GRID_LINES = 'rgba(255, 255, 255, 0.10)'; // type A

ColorDark.APP_BACKGROUND = ColorLight.APP_BACKGROUND_B;
ColorDark.APP_BACKGROUND_SECONDARY = ColorLight.APP_BACKGROUND_SECONDARY_B;
ColorDark.APP_BACKGROUND_TERTIARY = ColorLight.APP_BACKGROUND_TERTIARY_B;
ColorDark.CELL_BACKGROUND = cellBgDark;
ColorDark.BORDER_A = ColorLight.BORDER_B;
ColorDark.ICON_A = ColorLight.ICON_B;
ColorDark.DISCLOSURE_ICON_A = ColorLight.DISCLOSURE_ICON_B;
ColorDark.STATE_INACTIVE = ColorLight.STATE_INACTIVE_B;
ColorDark.SEGMENT_SELECTION_BORDER = ColorLight.SEGMENT_SELECTION_BORDER_B;
ColorDark.TEXT_INACTIVE = ColorLight.TEXT_SECONDARY_B;
ColorDark.TEXT_A = ColorLight.TEXT_B;
ColorDark.TEXT_SECONDARY_A = ColorLight.TEXT_SECONDARY_B;
ColorDark.TEXT_HINT_A = ColorLight.TEXT_HINT_B; // OTHERS - isn't necessary to use these ones in JS and CSS
// active miniserver

ColorDark.STATISTIC_BUBBLE_BG = 'rgba(0, 0, 0, 0.9)';
ColorDark.TIME_LINE_VALUE_INDICATORS = ColorLight.TEXT_B;
var Color = ColorLight;

var setColorObject = function setColorObject(darkMode) {
    //console.log("Setting color Object to " + (darkMode ? "dark" : "light"));
    if (!darkMode) {
        Color = ColorLight;
    } else {
        Color = ColorDark;
    }
};
