// TODO update file when changes where made:
// https://docs.google.com/a/loxone.com/document/d/1ly7Fyf0GO67SL5JefzQj2eXZ9Dh7bOjYg54UuzUgOv8/edit#
var SupportCode = {
    // 1000 - 1999 = General Issues
    PARSING_MESSAGE_FAILED: 1000,
    // parsing a (json) message failed
    DECRYPTING_RESPONSE_VALUE_FAILED: 1001,
    // decrypting of response value failed
    // 2000 - 2999 = Websocket
    WEBSOCKET_NOT_READY: 2000,
    // the websocket is not ready (connection/authentication)
    WEBSOCKET_ERROR: 2001,
    // a websocket error occurred
    WEBSOCKET_CLOSE: 2002,
    // the websocket closed with some reason
    WEBSOCKET_MANUAL_CLOSE: 2003,
    // the websocket was manually closed
    WEBSOCKET_TIMEOUT: 2004,
    // the timeout timed out
    WEBSOCKET_MISSING_HEADER: 2005,
    // a payload without header was received
    WEBSOCKET_WRONG_PACKAGE: 2006,
    // payload has different size then the header promised
    WEBSOCKET_NOT_SECURED: 2007,
    // the websocket connection isn't secured but we tried to send an encrypted command -> not possible/allowed!
    WEBSOCKET_OUT_OF_SERVICE: 2008,
    // the Miniserver is going to reboot
    // 3000 - 3499 = Statistic
    STATISTIC_MANUAL_CANCEL: 3000,
    // the request was canceled manually (navigate back)
    STATISTIC_NO_DATA_AVAILABLE: 3001,
    // no data for the requested date is available
    STATISTIC_DISPATCH_PREPARING_FAILED: 3002,
    // something went wrong while preparing to dispatch data
    STATISTIC_ERROR: 3003,
    // a 'general' error while processing a request, debug for more info!
    STATISTIC_DOWNLOAD_ERROR: 3004,
    // error while downloading statistic data
    STATISTIC_CACHE_ERROR: 3005,
    // error while loading cached statistic data
    STATISTIC_PARSE_ERROR: 3006,
    // error while parsing statistic data
    STATISTIC_BINARY_CONVERSION_ERROR: 3007,
    // error while loading cached statistic data
    STATISTIC_CACHING_ERROR: 3008,
    // error while caching statistic data
    // 3500 - 3999 = Worker

    /**
     * return a meaningful message for the code in order to reduce support tickets!
     * please "group" same codes/messages
     * if no popup should be shown for a code, simply skip it
     *
     * @param {SupportCode} code
     * @returns {{title: string, text: string, showContactSupportButton: boolean}|boolean} message to display to user or false if no popup should be shown for this code
     */
    getSupportMessage: function (code) {
        var message = {
            title: _("error"),
            text: "",
            showContactSupportButton: false
        }; // show specific messages!

        switch (code) {
            // General Issues
            case SupportCode.PARSING_MESSAGE_FAILED:
                message.text = _('support-code.general.1000') + " " + _('support-code.general.update-and-try-again');
                message.showContactSupportButton = true;
                break;
            // Websocket

            case SupportCode.WEBSOCKET_NOT_READY:
            case SupportCode.WEBSOCKET_ERROR:
            case SupportCode.WEBSOCKET_CLOSE:
            case SupportCode.WEBSOCKET_TIMEOUT:
                message.text = _('support-code.websocket.connection-problem');
                message.showContactSupportButton = false; // DON'T show button here - for our support :-)

                break;

            case SupportCode.WEBSOCKET_MISSING_HEADER:
            case SupportCode.WEBSOCKET_WRONG_PACKAGE:
                message.text = _('support-code.websocket.communication-problem') + " " + _('support-code.general.update-and-try-again');
                break;
            // Statistic

            case SupportCode.STATISTIC_DOWNLOAD_ERROR:
                message.title = _("statistics.download-failed");
                message.text = _('support-code.websocket.statistic-problem') + " " + _("please-try-again");
                break;

            case SupportCode.STATISTIC_DISPATCH_PREPARING_FAILED:
            case SupportCode.STATISTIC_ERROR:
            case SupportCode.STATISTIC_PARSE_ERROR:
            case SupportCode.STATISTIC_BINARY_CONVERSION_ERROR:
                console.error("Statistic Error, showing SupportMessage for " + code);
                message.title = _("statistics.download-failed");
                message.text = _('support-code.websocket.statistic-problem') + " " + _('support-code.general.update-and-try-again');
                break;
            // don't show a message:

            default:
                // show no popup
                return false;
                break;
        }

        if (message.showContactSupportButton) {
            // add code to message
            message.text = message.text + " (" + _('support-code', {
                code: code
            }) + ")";
        }

        return message;
    }
};
