'use strict';

/**
 * AppBranding
 * Documentation: https://docs.google.com/document/d/1etQARIqb2xPtAb3W9c1hvY8gP6awLzNtQIgOuyyZcCU/edit#
 * @constructor
 */

function AppBranding() {
}

AppBranding.prototype.isBrandingActive = function isBrandingActive() {
    return $('link[source="branding"]').length > 0; // check for styles only, because we reset the LxColors
};
/**
 * Resets the current branding and applies the default Loxone branding
 * @returns {*}
 */


AppBranding.prototype.resetBranding = function resetBranding() {
    //return Q.resolve();
    // remove old brandings
    this._removeScriptsAndStyles();

    if (BuildingType.AppBranding) {
        delete BuildingType.AppBranding;
    }

    delete this.brandLogoUrl;
    return this._loadLxColors().then(this._resetRxColors.bind(this)).then(function () {
        // Resetting Darkmode state (after we reloaded LxColors.js!)
        // Per default the light colors are set once we load the LxColors,
        // set them to the desired mode by resetting the current darkMode state
        NavigationComp.setDarkModeState(NavigationComp.getDarkModeState());
    });
};
/**
 * Applies the branding of the currently connected Miniserver
 * @param modificationDate
 * @returns {*}
 */


AppBranding.prototype.brandApp = function brandApp(modificationDate) {
    //return Q.resolve();
    this.modificationDate = modificationDate;

    if (modificationDate) {
        var activeMs = ActiveMSComponent.getActiveMiniserver(),
            currentModificationDate = activeMs.brandingDate;
        var brandingPromise;

        if (currentModificationDate === modificationDate) {
            // use the cached one
            brandingPromise = this._loadBranding();
        } else {
            // reload
            brandingPromise = this._reloadBranding();
        }

        return brandingPromise.depActiveMsThen(this._themeWithVars.bind(this), function () {
            // branding couldn't be loaded because of some reason..
            return false;
        }, null, this.resetBranding.bind(this));
    }

    var def = Q.defer();
    def.reject("no branding available");
    return def.promise;
};
/**
 * deletes the branding file for the given serialNo
 * @param serialNo
 * @returns {Promise}
 */


AppBranding.prototype.deleteBrandingFile = function deleteBrandingFile(serialNo) {
    return PersistenceComponent.deleteFile(this._buildBrandingFilename(serialNo));
};

AppBranding.prototype.getBrandLogoElement = function getBrandLogoElement(iconClass) {
    if (this.brandLogoUrl) {
        return '<img src="' + this.brandLogoUrl + '" class="' + iconClass + '"/>';
    }

    return ImageBox.getResourceImageWithClasses(Icon.LOXONE_LOGO, iconClass);
};
/**
 * loads the branding json from the persistence
 * @returns {Promise}
 * @private
 */


AppBranding.prototype._loadBranding = function _loadBranding() {
    return PersistenceComponent.loadFile(this._buildBrandingFilename(), DataType.OBJECT).depActiveMsThen(null, this._reloadBranding.bind(this));
};
/**
 * downloads the json from the Miniserver and persists it
 * @returns {Promise}
 * @private
 */


AppBranding.prototype._reloadBranding = function _reloadBranding() {
    return CommunicationComponent.sendViaHTTP("Branding/variables.json").then(function (brandingJson) {
        return PersistenceComponent.saveFile(this._buildBrandingFilename(), brandingJson, DataType.OBJECT).then(function () {
            return brandingJson;
        });
    }.bind(this));
};

AppBranding.prototype._buildBrandingFilename = function _buildBrandingFilename(serialNo) {
    return (serialNo || ActiveMSComponent.getActiveMiniserver().serialNo) + "_Branding.json";
};
/**
 * Fetches and reads the branding file
 * @param variables
 * @returns {Promise}
 * @private
 */
// var _processBranding = function _processBranding(variables) {
//     // Our Color Object
//
//     // Our themed files
//     var resourcesPromises = [true];
//     var files = zip.files;
//     for (var filePath in files) {
//         if (files.hasOwnProperty(filePath)) {
//
//             if (filePath.hasPrefix("www/") &&
//                 filePath.indexOf("/.") === -1) { // Zips from Mac have hidden files used for previewing the file in the Finder...
//                 var file = files[filePath];
//                 if (file.dir === false) {
//                     var suffix = _getSuffix(filePath);
//                     filePath = filePath.replace("www/", "");
//                     var asyncPromise = null;
//                     switch(suffix) {
//                         case "png":
//                         case "jpg":
//                         case "jpeg":
//                             asyncPromise = _imagePromiseToBlobURL(file.async("uint8array"), suffix);
//                             break;
//                         // We won't allow to brand SVGs
//                         /*case "svg":
//                          asyncPromise = file.async("string");
//                          break;*/
//                         default:
//                             console.log("Found unsupported file: " + filePath);
//                     }
//                     if (asyncPromise) {
//                         resourcesPromises.push(filePath);
//                         resourcesPromises.push(asyncPromise);
//                     }
//
//                 }
//             }
//         }
//     }
//     return Q.all(resourcesPromises);
// };

/**
 * This function implements a workaround for the broken SVG branding logo issue BG-I29798
 *
 * @param {*} brandLogo
 * @returns Promise
 */
const fixBrandingSVG = function fixBrandingSVG(brandLogo) {
    const prms = Q.defer();
    let logoURL = this._getURLOfBrandingResourceWithName(brandLogo);
    fetch(logoURL)
        .then((res) => {
            if (res.status === 200) {
                console.log('Logo fetched successfully');
                this.brandLogoUrl = logoURL;
                prms.resolve();
            } else {
                throw new Error('Error while fetching logo');
            }
        })
        .catch((err) => {
            console.error(err, "Applying fix for SVG branding logo");
            CommunicationComponent.sendViaHTTP(
                new URL(logoURL).pathname,
                EncryptionType.NONE,
                true,
            )
                .then((response) => {
                    if (response) {
                        const div = $('<div/>');
                        div.append(response);
                        var b64 =
                            'data:image/svg+xml;base64,' + btoa(div.html());
                        this.brandLogoUrl = b64;
                        prms.resolve();
                    } else {
                        this.brandLogoUrl =
                            ImageBox.getResourceImageWithClasses(
                                Icon.LOXONE_LOGO,
                                'brand-logo',
                            );
                    }
                })
                .catch((err) => {
                    console.error(err, "Applying fix for SVG branding logo, using default logo");
                    prms.resolve();
                });
        });
        return prms.promise;
};

/**
 * Applies the branding to the App (CSS and JS files, Wallmount background images are also replaced)
 * @param variables
 * @returns {Promise}
 * @private
 */
AppBranding.prototype._themeWithVars = function _themeWithVars(variables) {
    this._removeScriptsAndStyles(); // CSS (is loaded serial because of style-overwriting)


    var promises = [true]; // Every link or style tag with an href or source starting with "styles/main"

    var links = $('link[href^="styles/main"]');
    promises.push(this._handleCss(links.toArray(), variables)); // Adopt Javascript Color variables

    promises.push(this._loadLxColors(variables)); // Branding Logo

    var brandLogo = variables["brand-logo"];

    if (brandLogo) {
        if(Feature.BRANDING_SVG_BROKEN && !Feature.BRANDING_SVG_FIXED) {
            promises.push(fixBrandingSVG.call(this, brandLogo));
        } else {
            this.brandLogoUrl = this._getURLOfBrandingResourceWithName(brandLogo);
        }        
    }
    // Theme the background after applying the branding to the CSS
    // The styles from _themeBackground must be the last one to be added to prevent any style overwriting


    return Q.all(promises).then(this._updateRxColors.bind(this, variables)).then(this._themeBackground.bind(this, variables)).then(function () {
        // Resetting Darkmode state (after we reloaded LxColors.js!)
        // Per default the light colors are set once we load the LxColors,
        // set them to the desired mode by resetting the current darkMode state
        NavigationComp.setDarkModeState(NavigationComp.getDarkModeState());
    });
};


AppBranding.prototype._updateRxColors = function (variables) {
    window.Styles.colors.setBrandingColorSet(variables);
    return Q.resolve();
};


AppBranding.prototype._resetRxColors = function () {
    window.Styles.colors.unsetBrandingColorSet();
    return Q.resolve();
};


/**
 * loads all links until (serially)
 * @param links
 * @param variables
 * @returns {Promise}
 * @private
 */


AppBranding.prototype._handleCss = function (links = [], variables) {
    return this._themeCss(links.shift(), variables).then(function () {
        if (links.length > 0) {
            return this._handleCss(links, variables);
        }
    }.bind(this));
};
/**
 * themes the css text and appends it to the head to be applied
 * @param href
 * @param variables
 * @private
 */


AppBranding.prototype._themeCss = function _themeCss({ href } = { href: null }, variables) {
    var def = Q.defer();
    if (href) {
        $.ajax({
            url: href,
            dataType: "text"
        }).then(function (cssText) {
            for (var varName in variables) {
                if (variables.hasOwnProperty(varName)) {
                    var varValue = variables[varName],
                        regex = new RegExp("#.{3,6} ?\/\\*!--" + varName + "--\\*\/", "g"); // Why " ?" ___________________^
                    // Because sometimes we have "fill: $lox-blue !important;" and there may be a " " between
                    // the variable and "!important;" eg. fill:#83b817 /*!--green--*/ !important

                    cssText = cssText.replace(regex, varValue); // Adopt every ../resources url to an absolute path to not worry about the blobs origin
                    // This is used for the background image of every ComfortMode UI like screen

                    var basePath = location.origin + "/",
                        platform = PlatformComponent.getPlatformInfoObj().platform;

                    if (platform === PlatformType.IOS) {
                        basePath += "www/";
                    } else if (platform === PlatformType.Android) {
                        basePath += "android_asset/www/";
                    } else if (platform === PlatformType.Windows) {
                        basePath = "../";
                    }

                    cssText = cssText.replace(/\.\.\/resources/, basePath + "resources");
                }
            }

            var blobURL = createBlobURLObject(cssText, "text/css"),
                link = $('<link original="' + this.url + '" source="branding" rel="stylesheet">');
            link[0].onload = def.resolve;
            link.appendTo("head");
            link[0].href = blobURL;
        });// if you bind this, change this.url
    } else {
        def.resolve();
    }
    return def.promise;
};
/**
 * loads the LxColors file and appends it to head for execution
 * if variables are given, it brands the colors
 * @param variables
 * @returns {*}
 * @private
 */


AppBranding.prototype._loadLxColors = function _loadLxColors(variables) {
    return Q.resolve();
    /*var def = Q.defer();
    $.ajax({
        url: "scripts/UtilityComp/LxColors.js",
        dataType: "text"
    }).then(function (jsCode) {
        if (variables) {
            for (var varName in variables) {
                if (variables.hasOwnProperty(varName)) {
                    var varValue = variables[varName],
                        regex = new RegExp("([\"'])#.{3,6}([\"'])([;,]) ?\/\\*!--" + varName + "--\\*\/", "g");
                    jsCode = jsCode.replace(regex, "$1" + varValue + "$2$3");
                }
            }
        } // Don't use jQuery, it will add a cache buster url parameter


        var blobURL = createBlobURLObject(jsCode),
            script = $('<script></script>');
        script[0].onload = def.resolve;
        script.attr("original", this.url);
        script.attr("source", "branding");
        script.appendTo("head");
        script[0].src = blobURL;
    }); // if you bind this, change this.url

    return def.promise;*/
};
/**
 * Applies the background theme if defined
 * Any exceptions will be catched, we don't care if there is one
 * @param variables
 * @private
 */


AppBranding.prototype._themeBackground = function _themeBackground(variables) {
    var cssDef = Q.defer(); // Catch any potential errors to prevent the promise being rejected

    try {
        // Apply custom branding background gradient
        var background = variables['background'];

        if (background) {
            var type = background['type'] || "color",
                // Type defaults to "color"
                color = background['color'],
                // NOTE: Keep classes in sync with all the @extend uses
                cssFileString = "body .comfort-mode-background, body .screen, .comfort-mode-background.slow-device, body .slow-device.screen {",
                cssString = "background: " + color + ";"; // A color is required for the gradient (fallback if gradient is not supported by the browser)
            // If type is not a gradient we will set the color as background color

            if (color) {
                if (type.indexOf("gradient") !== -1) {
                    // Gradient Documentation: https://www.w3schools.com/css/css3_gradients.asp
                    var direction = background['direction'] || "",
                        // This is an optional property
                        colorStops = background['colorStops'] || [],
                        // This is an optional property
                        gradientContent = [direction].concat(colorStops),
                        gradientContentString = gradientContent.filter(function (str) {
                            // Only add non empty strings to the gradientContentString
                            return typeof str === "string" && str !== "";
                        }).join(","); //

                    cssString += "background: -webkit-" + type + "(" + gradientContentString + "); /* For Safari 5.1 to 6.0 */" + "background: -o-" + type + "(" + gradientContentString + "); /* For Opera 11.1 to 12.0 */" + "background: -moz-" + type + "(" + gradientContentString + ");  /* For Firefox 3.6 to 15 */" + "background: " + type + "(" + gradientContentString + "); /* Standard syntax */";
                }

                cssFileString += cssString + "}";
                var blobURL = createBlobURLObject(cssFileString, "text/css"),
                    link = $('<link source="branding" rel="stylesheet" >');
                link[0].onload = cssDef.resolve;
                link.appendTo("head");
                link[0].href = blobURL;
            } else {
                cssDef.resolve();
            }
        } else {
            cssDef.resolve();
        }
    } catch (e) {
        console.warn("Error while applying the background theme!");
        console.error(e); // Resolve the promise, we don't care if the background theme failed

        cssDef.resolve();
    }

    return cssDef.promise;
};

AppBranding.prototype._removeScriptsAndStyles = function _removeScriptsAndStyles() {
    $('link[source="branding"]').remove();
    $('script[source="branding"]').remove();
};

AppBranding.prototype._getURLOfBrandingResourceWithName = function _getURLOfBrandingResourceWithName(name) {
    var host = ActiveMSComponent.getCurrentUrl();

    if (!host.startsWith("http://") && !host.startsWith("https://")) {
        host = CommunicationComponent.getRequestProtocol() + host;
    }

    if (!host.endsWith("/")) {
        host = host + "/";
    }

    var urlPrefix = host + "Branding/";
    var cacheBuster = "?ts=" + moment(this.modificationDate).unix();
    return urlPrefix + name + cacheBuster;
};
