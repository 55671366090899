'use strict';
/**
 * BiometricHelper, enables you to get access to biometric features of the device (iOS and Android)
 * @constructor
 */

var BiometricHelper = function BiometricHelper() {
    this._isAvailable = false;
    this._authenticationDef = Q.defer();
    this._secretDef = Q.defer();
    this.name = "BiometricHelper";

    if (this.isSupported()) {
        this._staticLockScreen = new GUI.AppLockedScreen();
        this.unlockApp().then(function () {
            CompChannel.on(CCEvent.Resume, function () {
                this.unlockApp();
            }.bind(this));
            CompChannel.on(CCEvent.Pause, function () {
                this.lockApp();
            }.bind(this));
        }.bind(this));
    }
};
/**
 * Error codes enum to better know what is going on
 * @type {{PlatformNotSupported: number, ServiceNotAvailable: number, PluginMissing: number, NoBiometricsEnrolled: number, UserCanceled: number, BiometricError: number, PermissionDenied: number, Unknown: number}}
 */


BiometricHelper.prototype.ErrorCodes = {
    // Setup Errors
    PlatformNotSupported: 4,
    ServiceNotAvailable: 3,
    PluginMissing: 2,
    NoBiometricsEnrolled: 1,
    // Authenticating Errors
    UserCanceled: "cancel",
    BiometricError: -2,
    PermissionDenied: -3,
    Unknown: -4,
    MISSING_SECRET_TOKEN: -5,
    SecretNotFound: -6
};
/**
 * Possible States
 * @type {{Unauthorized: number, Waiting: number, Authorized: number}}
 */

BiometricHelper.prototype.States = {
    Unauthorized: 0,
    Waiting: 1,
    Authorized: 2
};
/**
 * Describes the current state of authentication
 * @type {number}
 */

BiometricHelper.prototype.State = BiometricHelper.prototype.States.Unauthorized;
/**
 * If biometric sensor is available
 * @type {boolean}
 */

BiometricHelper.prototype.hasBiometricSensor = false;
BiometricHelper.prototype.hasEnrolledBiometrics = false;
/**
 * Defines the available secret types
 * @note These secrets are automatically deleted when a miniserver is removed
 * @type {{VisuPw: string, UserPw: string}}
 */

BiometricHelper.prototype.SecretType = {
    VisuPw: "visuPw",
    UserPw: "userPw"
};
/**
 * Returns the matching glyph for the biometric type (face or touch) in use
 * @returns {string}
 */

BiometricHelper.prototype.getBiometricGlyph = function getBiometricGlyph() {
    if (PlatformComponent.getBiometricType() === PlatformComponent.BiometricType.FACE_ID) {
        return Icon.Biometric_ID.FACE;
    } else {
        return Icon.Biometric_ID.FINGER; // Finger print unlock is always the fallback
    }
};

BiometricHelper.prototype.getBiometricTypePhrase = function getBiometricTypePhrase() {
    var biometricType = PlatformComponent.getBiometricType();

    if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS) {
        if (biometricType === PlatformComponent.BiometricType.FACE_ID) {
            return _("biometrics.faceId");
        } else {
            return _("biometrics.touchId");
        }
    } else {
        return _("biometrics.finger-print-unlock");
    }
}; // Adds the so far defined prototypes to the BiometricHelper constructor, this way we can use them as static properties
// Note: Add any prototypes above this line to define static properties (variables, functions, ...)


Object.assign(BiometricHelper, BiometricHelper.prototype);
/**
 * If Biometric features are supported
 * Only iOS >= 8 and Android >= 6 are supported
 */

BiometricHelper.prototype.isSupported = function isSupported() {
    if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
        return parseFloat(PlatformComponent.getPlatformInfoObj().version) >= 6;
    } else {
        // iOS is generally supported
        // Our Targeted iOS Version is 9
        return PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS;
    }
};
/**
 * Checks if the system supports biometric hardware and if biometrics are enrolled
 * Biometrics = Fingerprint, Face ID
 */


BiometricHelper.prototype.checkAvailability = function checkAvailability() {
    var def = Q.defer();

    if (this.isSupported()) {
        // NOTE: Only call the availability check once! It will set the plugins callback ID and thus another call will
        // reset the callback id of any ongoing biometric request! (User closes the app while an authentication is been requested)
        if (!this._isAvailable) {
            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
                this._checkAndroidAvailability().then(function () {
                    this._isAvailable = true;
                    def.resolve();
                }.bind(this), function (e) {
                    def.reject(e);
                });
            } else {
                this._checkiOSAvailability().then(function () {
                    this._isAvailable = true;
                    def.resolve();
                }.bind(this), function (e) {
                    def.reject(e);
                });
            }
        } else {
            Debug.Biometric && console.log(this.name, "checkAvailability resolved isAvailable");
            def.resolve();
        }
    } else {
        def.reject(this.ErrorCodes.PlatformNotSupported);
    }

    return def.promise.then(function () {
        this.hasBiometricSensor = true;
        this.hasEnrolledBiometrics = true;
    }.bind(this), function (errorCode) {
        if (errorCode === this.ErrorCodes.NoBiometricsEnrolled) {
            this.hasBiometricSensor = true;
            this.hasEnrolledBiometrics = false;
        } else {
            this.hasBiometricSensor = false;
            this.hasEnrolledBiometrics = false;
        } // User is always authenticated if fingerprint is not available


        BiometricHelper.State = BiometricHelper.States.Authorized;
        return Q.reject(errorCode);
    }.bind(this));
};
/**
 * Triggers a biometric authentication
 * @param [allowBackup]
 * @param [reason]
 * @param [def]
 * @returns {*}
 */


BiometricHelper.prototype.authenticate = function authenticate(reason, def, allowBackup) {
    def = def || Q.defer(); // Check availability, this will set the flags
    // this.hasBiometricSensor
    // this.hasEnrolledBiometrics

    Debug.Biometric && console.log(this.name, "authenticate start");
    return this.checkAvailability().then(function success() {
        Debug.Biometric && console.log(this.name, "authenticate checkavailability");
        return this._handleNativeAuthPromise(function nativeAuthFunction() {
            Debug.Biometric && console.log(this.name, "handleNativeAuthPromise");

            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
                this._handleAndroidAuthentication(reason, def, allowBackup);
            } else {
                this._handleiOSAuthentication(reason, def);
            }

            return def.promise;
        }.bind(this));
    }.bind(this), function fail(e) {
        // Fingerprint is not available
        Debug.Biometric && console.log(this.name, "checkAvailability in authenticate rejected " + e);
        def.reject(e);
        return def.promise;
    }.bind(this));
};
/**
 * Authenticates the user with a given reason, directly resolves the promise if the user is already authenticated
 * @param reason
 */


BiometricHelper.prototype.unlockApp = function unlockApp(reason) {
    // Check if Fingerprint is activated
    var allowBackup = true;
    Debug.Biometric && console.log(this.name, "unlock");
    this._isAvailable = false;

    if (PersistenceComponent.getBiometricState()) {
        Debug.Biometric && console.log(this.name, "getBiometricState"); // Only handle authentication if user is unauthorized

        if (this.State === this.States.Unauthorized) {
            if (!reason) {
                reason = _("fingerprint.access-denied.alert-message");
            } // Set the state


            this.State = this.States.Waiting;

            this._staticLockScreen.setWaiting().show();

            this.authenticate(reason, this._authenticationDef, allowBackup);
        }
    } else {
        // Fingerprint is not enabled, resolve it!
        this._authenticationDef.resolve();
    }

    return this._authenticationDef.promise.then(function () {
        Debug.Biometric && console.log(this.name, "authenticationDef resolved");
        this.State = this.States.Authorized;

        if (this._staticLockScreen) {
            this._staticLockScreen.remove();
        }
    }.bind(this), function (errorCode) {
        Debug.Biometric && console.log(this.name, "" + JSON.stringify(errorCode));

        if (errorCode === this.ErrorCodes.NoBiometricsEnrolled || errorCode === this.ErrorCodes.ServiceNotAvailable) {
            Debug.Biometric && console.log(this.name, "authenticationDef rejected NoBiometricsEnrolled");
            PersistenceComponent.setBiometricState(false);
            this.State = this.States.Authorized;

            if (this._staticLockScreen) {
                this._staticLockScreen.remove();
            }
        } else {
            Debug.Biometric && console.log(this.name, "authenticationDef rejected");
            this._authenticationDef = Q.defer();
            this.State = this.States.Unauthorized;

            if (this._staticLockScreen) {
                this._staticLockScreen.setLocked().show();
            }

            return this._authenticationDef.promise;
        }
    }.bind(this));
};

BiometricHelper.prototype.lockApp = function lockApp() {
    if (PersistenceComponent.getBiometricState()) {
        this._staticLockScreen.setWaiting().show();
    } // Set the state to unauthorized
    // Android opens another activity when using the backup method (code or pattern)
    // This will result in the following state chain
    // (1)Unauthorized -> (2)Waiting -> (3)Unauthorized -> (4)Waiting -> (4)Authenticated
    // 1. User opens the App
    // 2. Authentication dialog will be shown
    // 3. User clicks on backup method because finger is dirty, wet or amputated...
    // 4. User enters code or pattern as a backup method, this will return him to our app again,
    //      State is Waiting to prevent showing the authentication dialog multiple times


    if (this.State === this.States.Authorized || this.State === this.States.Unauthorized) {
        this._authenticationDef = Q.defer();
        this.State = this.States.Unauthorized;
    }
};
/**
 * Gets the saved secret of Type (BiometricHelper.SecretType)
 * The secret will be resolved
 * @note It's important to chain the native authentication calls and also include a bit of a delay
 *       iOS must first hide the native authentication dialog before accepting a new one!
 * @param secretType
 * @param [targetMs] defines a miniserver other then the current active one
 * @param [shouldNotShowErrorPopup] if true, the error Popup won't be shown
 * @returns {*}
 */


BiometricHelper.prototype.getSecretOfType = function getSecretOfType(secretType, targetMs, shouldNotShowErrorPopup) {
    var secretToken;

    if (this._awaitingSecretDef) {
        return this._secretDef.promise.then(function () {
            return Q.delay(1000).then(function () {
                return this.getSecretOfType(secretType, targetMs);
            }.bind(this));
        }.bind(this), function () {
            return Q.delay(1000).then(function () {
                return this.getSecretOfType(secretType, targetMs);
            }.bind(this));
        }.bind(this));
    } else {
        this._secretDef = Q.defer();
        this._awaitingSecretDef = true;

        if (!targetMs) {
            targetMs = ActiveMSComponent.getActiveMiniserver();
        }

        secretToken = PersistenceComponent.getBiometricTokenForSecretType(secretType, targetMs.serialNo, targetMs.activeUser);

        if (secretToken) {
            console.log("BiometricHelper", "_getKeyForCurrentUser: st: " + secretToken + " sType: " + secretType);

            this._getSecretWithKeyForPlatform(this._getKeyForCurrentUser(secretType, targetMs), secretToken, null, this._secretDef);
        } else {
            this._secretDef.reject(this.ErrorCodes.MISSING_SECRET_TOKEN);
        }

        return this._secretDef.promise.then(function (password) {
            this._awaitingSecretDef = false;
            return password;
        }.bind(this), function (e) {
            this._awaitingSecretDef = false; // Don't disable on empty secret --> give the user the option to "fix" the issue by re-entering the new secret

            if (e !== this.ErrorCodes.UserCanceled && e !== this.ErrorCodes.SecretNotFound) {
                console.error("Deactivating biometric authentication due to error: " + e);
                console.error("Deactivating biometric authentication for secretType: " + secretType);
                PersistenceComponent.setBiometricTokenForSecretType(null, secretType);

                if (!shouldNotShowErrorPopup) {
                    NavigationComp.requestDebuglog("Biometric Disabled");
                }
            }

            return Q.reject(e);
        }.bind(this));
    }
};
/**
 * Saves the given secret with the provided secretType
 * @param secret
 * @param secretType
 * @param [targetMs] defines a miniserver other then the current active one
 * @returns {*}
 */


BiometricHelper.prototype.setSecretOfType = function setSecretOfType(secret, secretType, targetMs) {
    if (!targetMs) {
        targetMs = ActiveMSComponent.getActiveMiniserver();
    }

    var def = Q.defer(),
        biometricTypeString = this.getBiometricTypePhrase(),
        isBiometricSecretEnabled = PersistenceComponent.isBiometricSecretWithTypeEnabled(secretType, targetMs.serialNo, targetMs.activeUser),
        currentBiometricSecretToken = PersistenceComponent.getBiometricTokenForSecretType(secretType, targetMs.serialNo, targetMs.activeUser),
        acknowledgePopupConfig = this._getAcknoledgePopupConfigForSecretType(secretType);

    if (!PersistenceComponent.didShowBiometricOnboardDialogForSecretType(secretType, targetMs.serialNo, targetMs.activeUser)) {
        PersistenceComponent.setShowBiometricOnboardDialogForSecretType(true, secretType, targetMs.serialNo, targetMs.activeUser);
        return NavigationComp.showPopup(this._getOnboardingPopupConfigForSecretType(secretType), PopupType.GENERAL, true).then(function () {
            return this._setSecretWithKeyForPlatform(this._getKeyForCurrentUser(secretType, targetMs), secret, _("secured.password.biometric-dialog.native-save.desc", {
                biometricType: biometricTypeString
            }), def).then(function (secretToken) {
                PersistenceComponent.setBiometricTokenForSecretType(secretToken, secretType, targetMs.serialNo, targetMs.activeUser);
                return NavigationComp.showPopup(acknowledgePopupConfig, PopupType.GENERAL, true);
            }.bind(this));
        }.bind(this), function () {
            PersistenceComponent.setBiometricTokenForSecretType(null, secretType, targetMs.serialNo, targetMs.activeUser);
        });
    } else if (isBiometricSecretEnabled) {
        return this._setSecretWithKeyForPlatform(this._getKeyForCurrentUser(secretType, targetMs), secret, _("secured.password.biometric-dialog.native-save.desc", {
            biometricType: biometricTypeString
        }), def).then(function (secretToken) {
            PersistenceComponent.setBiometricTokenForSecretType(secretToken, secretType, targetMs.serialNo, targetMs.activeUser);

            if (!currentBiometricSecretToken) {
                return NavigationComp.showPopup(acknowledgePopupConfig, PopupType.GENERAL, true);
            }
        });
    } else {
        def.resolve();
        return def.promise;
    }
}; // Private


BiometricHelper.prototype._getOnboardingPopupConfigForSecretType = function _getOnboardingPopupConfigForSecretType(secretType) {
    var biometricTypeString = this.getBiometricTypePhrase(),
        currentUser = ActiveMSComponent.getCurrentUser(),
        config = {
            title: biometricTypeString,
            message: _("secured.password.biometric-dialog.ask.title", {
                biometricType: biometricTypeString,
                user: currentUser.name,
                passwordType: this.getPasswordTypeFromSecretType(secretType)
            }),
            buttonOk: _("activate"),
            buttonCancel: _("entry-point.initial-setup-screen.button.decline"),
            icon: this.getBiometricGlyph(),
            color: window.Styles.colors.stateActive
        };
    return config;
};

BiometricHelper.prototype._getAcknoledgePopupConfigForSecretType = function _getAcknoledgePopupConfigForSecretType(secretType) {
    var biometricTypeString = this.getBiometricTypePhrase(),
        config = {
            title: _("secured.password.biometric-dialog.activated.title", {
                biometricType: biometricTypeString
            }),
            message: _("secured.password.biometric-dialog.activated.desc", {
                biometricType: biometricTypeString,
                passwordType: this.getPasswordTypeFromSecretType(secretType)
            }),
            buttonOk: _("okay"),
            icon: this.getBiometricGlyph(),
            color: window.Styles.colors.stateActive
        };
    return config;
};

BiometricHelper.prototype._setSecretWithKeyForPlatform = function _setSecretWithKeyForPlatform(key, secret, reason, def) {
    return this._handleNativeAuthPromise(function nativeAuthFunction() {
        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
            return this._encryptSecreteForKeyOnAndroid(key, secret, reason, def);
        } else {
            return this._setKeyWithSecretToKeychainForiOS(key, secret, def);
        }
    }.bind(this));
};

BiometricHelper.prototype._getSecretWithKeyForPlatform = function _getSecretWithKeyForPlatform(key, token, reason, def) {
    return this._handleNativeAuthPromise(function nativeAuthFunction() {
        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
            return this._decryptSecreteForKeyOnAndroid(key, token, reason, def);
        } else {
            return this._getSecretWithKeyFromKeychainForiOS(key, reason, def);
        }
    }.bind(this));
};
/**
 * Handles Androids Authentication
 * @param allowBackup
 * @param reason
 * @private
 */


BiometricHelper.prototype._handleAndroidAuthentication = function _handleAndroidAuthentication(reason, def, allowBackup) {
    // Why "dummy" We just save something to trigger the finger print dialog
    return this._encryptSecreteForKeyOnAndroid("androidAuth", "dummy", reason, def, allowBackup);
};

BiometricHelper.prototype._encryptSecreteForKeyOnAndroid = function _encryptSecreteForKeyOnAndroid(key, secrete, reason, def, allowBackup) {
    var encryptConfig = {
        clientId: "com.loxone.kerberos",
        dialogTitle: _("fingerprint.access-denied.desc", {
            authMethod: this.getBiometricTypePhrase()
        }),
        dialogMessage: reason ? reason : _("Please Authenticate"),
        // This key is predefined by the iOS keychain plugin, it has the same string that we need...
        username: key,
        password: secrete,
        disableBackup: !allowBackup // Android won't return the password if the backup is used!

    };

    if (allowBackup) {
        FingerprintAuth.encrypt(encryptConfig, function (fingerResult) {
            def.resolve();
        }.bind(this), function (err) {
            def.reject(this._AndroidFpErrorToBiometricHelperError(err));
        }.bind(this));
    } else {
        FingerprintAuth.encrypt(encryptConfig, function (fingerResult) {
            if (fingerResult.withFingerprint) {
                def.resolve(fingerResult.token);
            } else {
                def.reject(this.ErrorCodes.Unknown);
            }
        }.bind(this), function (err) {
            def.reject(this._AndroidFpErrorToBiometricHelperError(err));
        }.bind(this));
    }

    return NavigationComp.showPopup({
        defer: def
    }, PopupType.NATIVE_POPUP, true);
};

BiometricHelper.prototype._decryptSecreteForKeyOnAndroid = function _decryptSecreteForKeyOnAndroid(key, secrete, reason, def) {
    var decryptConfig = {
        clientId: "com.loxone.kerberos",
        dialogTitle: _("fingerprint.access-denied.desc", {
            authMethod: this.getBiometricTypePhrase()
        }),
        dialogMessage: reason ? reason : _("Please Authenticate"),
        // This key is predefined by the iOS keychain plugin, it has the same string that we need...
        username: key,
        token: secrete,
        disableBackup: true // Android won't return the password if the backup is used!

    };
    FingerprintAuth.decrypt(decryptConfig, function (fingerResult) {
        if (fingerResult.withFingerprint) {
            def.resolve(fingerResult.password);
        } else {
            def.reject(this.ErrorCodes.Unknown);
        }
    }.bind(this), function (err) {
        console.error(this.name, "--------------------------------------");
        console.error(this.name, "get secret from keychain with key --> " + key + " failed with response --> " + JSON.stringify(err));
        console.error(this.name, "--------------------------------------");
        def.reject(this._AndroidFpErrorToBiometricHelperError(err));
    }.bind(this));
    return NavigationComp.showPopup({
        defer: def
    }, PopupType.NATIVE_POPUP, true);
};
/**
 * Handles iOS Authentication
 * @param reason
 * @param def
 * @private
 */


BiometricHelper.prototype._handleiOSAuthentication = function _handleiOSAuthentication(reason, def) {
    if (!reason) {
        reason = _("Please Authenticate"); // This key is predefined by the iOS keychain plugin, it has the same string that we need...
    }

    window.plugins.touchid.verifyFingerprint(reason, function success(msg) {
        def.resolve();
    }.bind(this), function fail(e) {
        var rejectValue = this.ErrorCodes.Unknown;

        if (e && e.code) {
            rejectValue = this._iOSOSStatusCodeToBiometricHelperError(e.code);
        }

        console.error("BiometricHelper: " + rejectValue);
        def.reject(rejectValue);
    }.bind(this));
    return def.promise;
};

BiometricHelper.prototype._setKeyWithSecretToKeychainForiOS = function _setKeyWithSecretToKeychainForiOS(key, secret, def) {
    var keyChainDef = Q.defer(); // We should show the native Biometric ID dialog if we set a secret (This mirrors the Android behaviour)

    return this._handleiOSAuthentication(null, def).then(function () {
        Keychain.set(function () {
            keyChainDef.resolve("saved");
        }, function () {
            keyChainDef.reject(Q.reject(this.ErrorCodes.Unknown));
        }.bind(this), key, secret, true);
        return keyChainDef.promise;
    });
};

BiometricHelper.prototype._getSecretWithKeyFromKeychainForiOS = function _getSecretWithKeyFromKeychainForiOS(key, reason, def) {
    Keychain.get(function (secret) {
        if (secret) {
            def.resolve(secret);
        } else {
            // User Canceled!
            def.reject(this.ErrorCodes.UserCanceled);
        }
    }.bind(this), function (e) {
        console.error(this.name, "--------------------------------------");
        console.error(this.name, "get secret from keychain with key --> " + key + " failed with response --> " + JSON.stringify(e));
        console.error(this.name, "--------------------------------------");

        if (e && e.code) {
            def.reject(this._iOSOSStatusCodeToBiometricHelperError(e.code));
        } else {
            def.reject(this.ErrorCodes.Unknown);
        }
    }.bind(this), key, reason);
    return NavigationComp.showPopup({
        defer: def
    }, PopupType.NATIVE_POPUP, true);
};
/**
 * Checks if Androids fingerprint sensor is available
 * @private
 */


BiometricHelper.prototype._checkAndroidAvailability = function _checkAndroidAvailability() {
    var def = Q.defer();

    if (FingerprintAuth) {
        FingerprintAuth.isAvailable(function (result) {
            if (result.isAvailable) {
                if (result.hasEnrolledFingerprints) {
                    def.resolve();
                } else {
                    def.reject(this.ErrorCodes.NoBiometricsEnrolled);
                }
            } else {
                def.reject(this.ErrorCodes.ServiceNotAvailable);
            }
        }.bind(this), function (e) {
            def.reject(this.ErrorCodes.ServiceNotAvailable);
        }.bind(this));
    } else {
        def.reject(this.ErrorCodes.PluginMissing);
    }

    return def.promise;
};
/**
 * Check if iOS fingerprint sensor is available
 * @private
 */


BiometricHelper.prototype._checkiOSAvailability = function _checkiOSAvailability() {
    var def = Q.defer();

    if (window.plugins.touchid) {
        window.plugins.touchid.isAvailable(function success() {
            def.resolve();
        }.bind(this), function fail(msg) {
            def.reject(this._iOSLAErrorToBiometricHelperError(msg));
        }.bind(this));
    } else {
        def.reject(this.ErrorCodes.PluginMissing);
    }

    return def.promise;
};
/**
 * Convert Android Fingerprint error to BiometricHelper error
 * @param fpError
 * @returns {number}
 * @private
 */


BiometricHelper.prototype._AndroidFpErrorToBiometricHelperError = function _AndroidFpErrorToBiometricHelperError(fpError) {
    var error = this.ErrorCodes.Unknown;

    switch (fpError) {
        case FingerprintAuth.ERRORS.FINGERPRINT_CANCELLED:
            error = this.ErrorCodes.UserCanceled;
            break;

        case FingerprintAuth.ERRORS.FINGERPRINT_ERROR:
            error = this.ErrorCodes.BiometricError;
            break;

        case FingerprintAuth.ERRORS.FINGERPRINT_PERMISSION_DENIED:
        case FingerprintAuth.ERRORS.FINGERPRINT_PERMISSION_DENIED_SHOW_REQUEST:
            error = this.ErrorCodes.PermissionDenied;
            break;

        case FingerprintAuth.ERRORS.SECRET_NOT_FOUND:
            error = this.ErrorCodes.SecretNotFound;
            break;
    }

    return error;
};
/**
 * Convert iOS LA (Local Authentication) error to BiometricHelper error
 * @param laError
 * @returns {number}
 * @private
 */


BiometricHelper.prototype._iOSLAErrorToBiometricHelperError = function _iOSLAErrorToBiometricHelperError(laError) {
    if (laError && laError.code) {
        var errorCode;

        switch (laError.code) {
            case this._LAErrorCodes.UserCanceled:
                errorCode = this.ErrorCodes.UserCanceled;
                break;

            case this._LAErrorCodes.TouchIdNotAvailable:
                errorCode = this.ErrorCodes.ServiceNotAvailable;
                break;

            case this._LAErrorCodes.PasscodeNotSet:
            case this._LAErrorCodes.TouchIdNotEnrolled:
                errorCode = this.ErrorCodes.NoBiometricsEnrolled;
                break;

            default:
                errorCode = this.ErrorCodes.Unknown;
        }

        return errorCode;
    } else {
        return this.ErrorCodes.ServiceNotAvailable;
    }
};
/**
 * Convert iOS OSStatus error to BiometricHelper error
 * @param osStatusCode
 * @returns {number}
 * @private
 */


BiometricHelper.prototype._iOSOSStatusCodeToBiometricHelperError = function _iOSLAErrorToBiometricHelperError(osStatusCode) {
    if (osStatusCode) {
        var errorCode;

        switch (osStatusCode) {
            case this._OSStatusCode.LockDuringAuth:
            case this._OSStatusCode.UserCanceled:
                errorCode = this.ErrorCodes.UserCanceled;
                break;

            case this._OSStatusCode.SecretNotFound:
                errorCode = this.ErrorCodes.SecretNotFound;
                break;

            default:
                errorCode = this.ErrorCodes.Unknown;
        }

        return errorCode;
    } else {
        return this.ErrorCodes.Unknown;
    }
};
/**
 * Gets the key for the current user
 * @param type
 * @param [targetMs]
 * @returns {string}
 * @private
 */


BiometricHelper.prototype._getKeyForCurrentUser = function _getKeyForCurrentUser(type, targetMs) {
    if (!targetMs) {
        targetMs = ActiveMSComponent.getActiveMiniserver();
    }

    if (targetMs && type) {
        return targetMs.serialNo + "." + targetMs.activeUser + "." + type;
    } else {
        console.log("No targetMs, or missing suffix!");
    }
};

BiometricHelper.prototype.getPasswordTypeFromSecretType = function getPasswordTypeFromSecretType(secretType) {
    var passwordType;

    switch (secretType) {
        case this.SecretType.VisuPw:
            passwordType = _("visu-password");
            break;

        default:
            passwordType = _("password");
    }

    return passwordType;
};
/*
 * Ensures that there is just one native authentication promise active, otherwise await the completion of the
 */


BiometricHelper.prototype._handleNativeAuthPromise = function _handleNativeAuthPromise(execFunc) {
    return (this._nativeAuthPromise || Q.resolve()).then(function () {
        this._nativeAuthPromise = execFunc();
        return this._nativeAuthPromise.then(function (result) {
            this._nativeAuthPromise = null;
            return Q.resolve(result);
        }.bind(this), function (e) {
            this._nativeAuthPromise = null;
            return Q.reject(e);
        }.bind(this));
    }.bind(this));
};
/**
 * iOS LocalAuthentication Error codes
 * https://developer.apple.com/reference/localauthentication/laerror.code
 * @type {{AuthenticationFailed: number, UserCanceled: number, UserFallback: number, SystemCancel: number, PasscodeNotSet: number, TouchIdNotAvailable: number, TouchIdNotEnrolled: number, TouchIdLockout: number, AppCancel: number, InvalidContext: number}}
 * @private
 */


BiometricHelper.prototype._LAErrorCodes = {
    AuthenticationFailed: -1,
    UserCanceled: -2,
    UserFallback: -3,
    SystemCancel: -4,
    PasscodeNotSet: -5,
    TouchIdNotAvailable: -6,
    TouchIdNotEnrolled: -7,
    TouchIdLockout: -8,
    AppCancel: -9,
    InvalidContext: -10
};
/**
 * iOS LocalAuthentication Error codes
 * https://developer.apple.com/reference/localauthentication/laerror.code
 * @type {{AuthenticationFailed: number, UserCanceled: number, UserFallback: number, SystemCancel: number, PasscodeNotSet: number, TouchIdNotAvailable: number, TouchIdNotEnrolled: number, TouchIdLockout: number, AppCancel: number, InvalidContext: number}}
 * @private
 */

BiometricHelper.prototype._OSStatusCode = {
    UserCanceled: -128,
    LockDuringAuth: -25293,
    SecretNotFound: -25300
};
