'use strict';

var LxColorUtils = {};
var HUE_WARM_WHITE = 50;
var HUE_COLD_WHITE = 200;
var PICKER_MODE = {
    NONE: 0,
    LUMITECH: 1,
    HSV: 2,
    TEMPERATURE: 3,
    SEQUENCE: 4
};
/**
 * This method is used e.g. in the color picker state container to convert color strings received from the miniserver
 * to objects that can be used within this app.
 * @param colorString
 * @returns {*}
 */

LxColorUtils.createColorObjectFromColorString = function createColorObjectFromColorString(colorString) {
    //TODO-woessto: remove once lumitech was replaced with temp
    if (colorString.indexOf("lumitech") >= 0) {
        console.error("Lumitech contained in colorString! Was converted to temp!");
        colorString = colorString.replace("lumitech", "temp");
    }

    if (colorString.indexOf('hsv') === 0) {
        // RGB
        return LxColorUtils.createHSVObjectFromHSVString(colorString);
    } else if (colorString.indexOf('temp') === 0) {
        // Color Temperature
        return LxColorUtils.createColorObjectFromTepmeratureString(colorString);
    } else if (colorString.indexOf("#") === 0) {
        return LxColorUtils.createHSVObjectFromRGBString(colorString);
    } else {
        throw new Error("Couldn't create a color object form the string '" + colorString + "'. Neither hsv nor temp");
    }
};
/**
 * This method converts a HSV string as received by the miniserver and converts it to a color object that can be used
 * within this app.
 * @param string the input hsv string (e.g. "hsv(0,100,100)")
 * @returns {{type: number, hue: Number, sat: Number, brightness: Number}}
 */


LxColorUtils.createHSVObjectFromHSVString = function createHSVObjectFromHSVString(string) {
    var values = string.substring(4, string.length - 1).split(',');
    return {
        type: PICKER_MODE.HSV,
        hue: parseInt(values[0]),
        sat: parseInt(values[1]),
        brightness: parseInt(values[2])
    };
};
/**
 * This method converts a RGB string as used within this app and converts it to a HSV color object.
 * @param string the input hsv string (e.g. "#FFFFFF", "#fff", 'rgba(0,0,0,0.87)')
 * @returns {{type: number, hue: Number, sat: Number, brightness: Number}}
 */


LxColorUtils.createHSVObjectFromRGBString = function createHSVObjectFromRGBString(string) {
    var hsvVals = tinycolor(string).toHsv(),
        hue,
        sat,
        brightness;
    sat = hsvVals.s * 100;
    brightness = hsvVals.v * 100;
    hue = hsvVals.h;
    return {
        type: PICKER_MODE.HSV,
        hue: parseInt(hue),
        sat: parseInt(sat),
        brightness: parseInt(brightness)
    };
};
/**
 * This method converts a color temperature string as received by the miniserver and converts it to a color object that
 * can be used within this app.
 * @param string    the input color temperature string (e.g. "temp(100,4483)" )
 * @returns {{type: number, brightness: Number, kelvin: Number}}
 */


LxColorUtils.createColorObjectFromTepmeratureString = function createColorObjectFromTepmeratureString(string) {
    var values = string.substring(5, string.length - 1).split(',');
    return {
        type: PICKER_MODE.TEMPERATURE,
        brightness: parseInt(values[0]),
        kelvin: parseInt(values[1])
    };
};
/**
 * Takes a color object (expects HSV type) and converts it to a HSL text
 * @param color         color object with type HSV
 * @returns {string}    e.g. hsl(120,100%,80%)
 */


LxColorUtils.createHSLTextFromColorObject = function createHSLTextFromColorObject(color) {
    if (color.type === PICKER_MODE.TEMPERATURE) {
        color = LxColorUtils.convertTemperatureColorObjectToHSV(color);
    }

    if (color.type !== PICKER_MODE.HSV) {
        throw new Error("Expects an HSV color object as input for converting to HSL!");
    }

    var hslArr = LxColorUtils.hsb2hsl(color.hue, color.sat, color.brightness),
        text = "hsl(" + hslArr[0] + ", ";
    text += parseInt(hslArr[1] * 100) + "%, ";
    text += parseInt(hslArr[2] * 100) + "%";
    text += ")";
    return text;
};
/**
 * Takes a color object (expects HSV type) and converts it to a HSLA text --> brightness becomes alpha & brightness will
 * be set to 100% fixed.
 * @param color         color object with type HSV
 * @returns {string}    e.g. hsl(120,100%,100%,80%)
 */


LxColorUtils.createHSLATextFromColorObject = function createHSLATextFromColorObject(color) {
    if (color.type === PICKER_MODE.TEMPERATURE) {
        color = LxColorUtils.convertTemperatureColorObjectToHSV(color);
    }

    if (color.type !== PICKER_MODE.HSV) {
        throw new Error("Expects an HSV color object as input for converting to HSL!");
    }

    var hslArr = LxColorUtils.hsb2hsl(color.hue, color.sat, 100),
        text = "hsla(" + hslArr[0] + ", ";
    text += parseInt(hslArr[1] * 100) + "%, ";
    text += parseInt(hslArr[2] * 100) + "%, ";
    text += color.brightness / 100; // alpha

    text += ")";
    return text;
};

LxColorUtils.getCSSRGBPropertyFromColorObject = function getCSSRGBPropertyFromColorObject(color, includeBrightness) {
    if (color.type === PICKER_MODE.TEMPERATURE) {
        var rgbFromKelvin = colorTemperatureToRgb(color.kelvin);
        return "rgb(" + Math.round(rgbFromKelvin.r) + "," + Math.round(rgbFromKelvin.g) + "," + Math.round(rgbFromKelvin.b) + ")";
    } else if (color.type === PICKER_MODE.HSV) {
        var brightness = includeBrightness ? color.brightness / 100 : color.brightness > 0 ? 1 : 0;
        var rgbFromHsv = hsvToRgb(color.hue / 360, color.sat / 100, brightness);
        return "rgb(" + Math.round(rgbFromHsv[0]) + "," + Math.round(rgbFromHsv[1]) + "," + Math.round(rgbFromHsv[2]) + ")";
    }
};
/**
 * Will adopt the input color accordingly, so it'll look nicely. To do so, the brightness will be forced to 100%, instead
 * the original brightness will impact the alpha of the resulting hsla-text.
 * @param color
 * @return {*}
 */


LxColorUtils.getHSLATextForDrawing = function getHSLATextForDrawing(color) {
    var drawColor = cloneObject(color); // update the brightness of each color for the UI.

    if (color.brightness || color.brightness === 0) {
        drawColor.brightness = getBrightnessForUI(drawColor.brightness);
    }

    return LxColorUtils.createHSLATextFromColorObject(drawColor);
};
/**
 * Takes a color object as input and returns a string that can be sent to the miniserver.
 * @param color             the object to 'stringify'
 * @returns {string}        the text that represents the color object.
 */


LxColorUtils.getStringFromColorObject = function getStringFromColorObject(color) {
    var txt = null;

    switch (color.type) {
        case PICKER_MODE.HSV:
            txt = Commands.format(Commands.COLOR_PICKER_V2.HSV, color.hue, color.sat, color.brightness);
            break;

        case PICKER_MODE.TEMPERATURE:
            txt = Commands.format(Commands.COLOR_PICKER_V2.TEMP, color.brightness, color.kelvin);
            break;

        default:
            break;
    }

    if (!txt) {
        throw new Error("Could not create string for the color: " + JSON.stringify(color));
    }

    return txt;
};

LxColorUtils.convertTemperatureColorObjectToHSV = function convertTemperatureColorObjectToHSV(color) {
    var cold = 6500,
        warm = 2700,
        middle = (cold + warm) / 2,
        percent,
        hue;

    if (color.kelvin < middle) {
        hue = HUE_WARM_WHITE;
        percent = 1 - (color.kelvin - warm) / (middle - warm);
    } else {
        hue = HUE_COLD_WHITE;
        percent = (color.kelvin - middle) / (cold - middle);
    }

    return {
        type: PICKER_MODE.HSV,
        hue: hue,
        sat: parseInt(100 * percent),
        brightness: color.brightness
    };
};
/**
 * Taken from http://stackoverflow.com/questions/3423214/convert-hsb-hsv-color-to-hsl
 * @param hue
 * @param saturation
 * @param brightness
 * @returns {[h,s,l]}
 */


LxColorUtils.hsb2hsl = function hsb2hsl(hue, saturation, brightness) {
    // both sv and sl values are in [0, 1]
    var h = hue,
        s = saturation / 100,
        // ensures the values are between 0 and 1
        v = brightness / 100,
        // ensures the values are between 0 and 1
        l = (2 - s) * v / 2;

    if (l !== 0) {
        if (l === 1) {
            s = 0;
        } else if (l < 0.5) {
            s = s * v / (l * 2);
        } else {
            s = s * v / (2 - l * 2);
        }
    }

    return [h, s, l];
};
/**
 * From https://gist.github.com/mjackson/5311256
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   r       The red color value
 * @param   g       The green color value
 * @param   b       The blue color value
 * @return  Array   The HSV representation
 */


LxColorUtils.rgb2hsv = function rgb2hsv(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    var h,
        s,
        v = max;
    var d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max === min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;

            case g:
                h = (b - r) / d + 2;
                break;

            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    return [h, s, v];
};

LxColorUtils.hsv2rgb = function hsv2rgb(h, s, v) {
    let f= (n, k=(n+h/60)%6) => v - v*s*Math.max(Math.min(k, 4-k, 1), 0);
    return [f(5), f(3), f(1)];
}

LxColorUtils.rgb2hsvString = function rgb2hsvString(r, g, b) {
    var res = LxColorUtils.rgb2hsv(r, g, b);
    return "hsv(" + res[0] + "," + res[1] + "," + res[2] + ")";
}; // Extracted to make it easier to find when changed (e.g. to max brightness value instead of 100%)


LxColorUtils.DEFAULT_BRIGHTNESS = 100;
